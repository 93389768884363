export const phoneMaxLength = 10;

export const activateCardConstants = {
  heading: `# Activate your card`,
  subHeading: `### Please fill out the details below to activate your card`,
  activateCardText: `${process.env.REACT_APP_ACTIVATE_CARD_TEXT}`,
  activationCodeSubText: `This can be located on the back of your card. It is alphanumerical and 9 digits long`,
  phoneNumberSubText: `${process.env.REACT_APP_PHONE_SUB_TEXT}`,
  activateCardButtonText: `Activate Now`,
  termsConditionsCheckValue: 'true',
  termsConditionsText: `I understand and agree to the gift card [Terms & Conditions*](${process.env.REACT_APP_TERMS_LINK})`,
  defaultValue: {
    activationCode: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    termsConditionsCheck: false,
  },
  validations: {
    activationCode: {
      id: 'activationCode',
      required: 'Activation Code is required',
      format: `Activation Code is invalid`,
    },
    email: {
      id: 'email',
      required: 'Email address is required',
      format: 'Email address is invalid',
    },
    firstName: {
      id: 'firstName',
      required: 'First given name is required',
      format: `First given name is invalid`,
    },
    lastName: {
      id: 'lastName',
      required: 'Last name is required',
      format: `Last name is invalid`,
    },
    phoneNumber: {
      id: 'phoneNumber',
      required: 'Mobile number is required',
      format: 'Mobile number must start with 0',
      minLength: `Mobile number must be ${phoneMaxLength} digits`,
      maxLength: `Mobile number must be ${phoneMaxLength} digits`,
    },
    confirmPhoneNumber: {
      id: 'confirmPhoneNumber',
      required: 'Confirm Mobile number is required',
      format: 'Confirm Mobile number must start with 0',
      minLength: `Confirm Mobile number must be ${phoneMaxLength} digits`,
      maxLength: `Confirm Mobile number must be ${phoneMaxLength} digits`,
    },
    termsConditionsCheck: {
      id: 'termsConditionsCheck',
      required: 'Terms & Conditions is required',
    },
    authCode: {
      id: 'authCode',
      required: ' Code is required',
      format: `Authentication Code is invalid`,
    },
  },
};
